import React from 'react'
import { Header } from './header'
import { Temoins } from "./temoignages";
import { Section } from './main'
import  {Welcom}from './formationsPage'
import { Form } from './form'
export const Formations = ()=>{
    let text_1 = 'Bienvenue ici vous allez apprendre à manipuler'
    let text_2 = 'de grandes quantités de données , developper des applications web'            
    let text_3 = 'robustes et performantes tout en assurant la sécurité de vos applications'
    return(
    <>
        <Header/>
        <Section text_1={text_1} text_2={text_2} text_3={text_3} Id={'ID'}/>
        <Welcom/>
        <Form/>
        <Temoins />
        <br/>
        <br/>
        <br/>
    </>
    )
}