import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SendCookie } from "./actions/action";
import { useNavigate } from "react-router-dom";


export const NavBar = ({ props }) => {
  const [dis, setDis] = useState(false);
  const [data , setData] = useState('')
  const allow =  useSelector(state=>state.reducer.cookie)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  function nav(link){
    navigate(`${link}`)
  }

  const getData = ()=>{
    setData(allow)
  }

  useEffect(()=>{
    getData()
    
    if(data.Authorization==='True'){
      setDis(true)
    }else{
      setDis(false)
    }
  })

  return (
    <>
      <div className="nav">
        <nav id="nav">
          <i
            class="material-icons"
            id="close"
            onClick={() => {
              document.querySelector("#nav").classList.remove("active");
            }}
          >
            &#xe5cd;
          </i>
          <ul>
            <li>
              <a href="/">Accueil</a>
            </li>
            <li>
              <a href="" onClick={(e)=>{
                e.preventDefault()
                nav('/formations')
              }}>Formations</a>
            </li>
            <li>
              <a href="" onClick={(e)=>{
                e.preventDefault()
                nav('/coming')
              }}>Services</a>
            </li>
            <li>
            <a href="" onClick={(e)=>{
                e.preventDefault()
                nav('/coming')
              }}>Communauté</a>
            </li>
            {dis && (
              <li>
              <a href="" onClick={(e)=>{
                e.preventDefault()
                nav('/espace')
              }}>Mon espace</a>
              </li>
            )}
            {dis && (
              <li>
                <a href="" onClick={(e)=>{
                e.preventDefault()
                nav('/freecourses')
              }}>Cours gratuits</a>
              </li>
            )}
            {!dis && (
              <li>
                <a href="" onClick={(e)=>{
                e.preventDefault()
                nav('/regester/signin')
              }}>Connexion</a>
              </li>
            )}
            {dis && (
              <li>
                <a
                  style={{cursor:'pointer'}}
                  onClick={() => {
                    if(confirm('Voulez vous vraiment vous déconnecté')){
                    localStorage.removeItem("Cookie");
                    axios ({
                      method:'post',
                      data:{Numero:localStorage.getItem('Number')},
                      withCredentials:true,
                      url:`${process.env.REACT_APP_API_URL_BACK}/submit/logout`
                    })
                    .then((res)=>{
                     setTimeout(()=>{
                       nav('/home')
                     },1000)
                      dispatch(SendCookie({Authorization:'False'}))
                    })
                    .catch((err)=>{
                      alert('Une erreur est survenue lors de votre deconnexion !')
                      console.error(err)
                    })
                  }}}
                >
                  Deconnexion
                </a>
              </li>
            )}
            <li>
              {props && (
              <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                document.querySelector("#join").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              Contact
            </a>
              )}
            </li>
            {props && (
              <li>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector("#h2").scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }}
                >
                  A propos
                </a>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </>
  );
};
