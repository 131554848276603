import React, { useEffect } from "react";
import { Homeheader } from "./homeHeader";
import { Section } from "./main";
import { ProductHome } from "./serviceListHome";
import { About } from "./presentation";
import { Form } from "./form";
import { Footer } from "./footer";
import { Alert } from "./alert";
import { useState } from "react";
import { Chat } from "./chat";
import { useSelector } from "react-redux";
import axios from "axios";


export const Avis = ({ avis , onClick }) => {

const [textarea, setTextarea] = useState("");
const [prenom, setPrenom] = useState("");
const [nom, setNom] = useState("");
const [email, setEmail] = useState("");
const [numero, setNumero] = useState("");
const [aff, setAff] = useState(false);

useEffect(() => {
  setPrenom(localStorage.getItem("Prenom"));
  setNom(localStorage.getItem("Nom"));
  let mail = JSON.parse(localStorage.getItem('maskedData'));
    if (mail) mail = mail.email  
  else  mail = ''
  let numero = JSON.parse(localStorage.getItem('maskedData'));
    if (numero)  numero = numero.contact  
  else  numero = ''
  setNumero(numero);
  setEmail(mail);
}, []);
const submit = () => {
  if (textarea !== "") {
    axios({
      method: "post",
      withCredentials: true,
      url: `${process.env.REACT_APP_API_URL_BACK}/submit/mess/send`,
      data: {
        message: textarea,
        prenom: prenom,
        numero: numero,
        email: email,
        nom: nom,
        limit: 10,
      },
    })
      .then((res) => {
        alert("Merci pour votre message ensemble codons le futur");
      })
      .catch((err) => {
        alert("Oups connectez-vous d'abord ! ou ressayez plus tard ");
      });
  } else {
    alert("Veuillez remplir le message !");
  }
};

  return (
    <>
      {avis && (
        <Alert
          message={
            <>
              <br />
              <div className="menu-links">
                <div className="rate">
                  <textarea
                    placeholder="Votre avis compte"
                    onChange={(e) => setTextarea(e.target.value)}
                  ></textarea>
                  <input
                    type="submit"
                    value={"Envoyez le message"}
                    onClick={submit}
                  />
                </div>
              </div>
              <br />
            </>
          }
          titre={`Juste 2 minutes pour repondre à ce petit message ci dessus svp `}
          sous={
            "Dites nous ce que vous pensez de nous ou signalez nous un probléme sur notre platforme ou que voulez vous qu'on améliore ou ajoute ?"
          }
          dis={avis}
          button={
            <div className="chil">
              <p
                class="btn"
                onClick={() => {
                  window.location.href = `${process.env.REACT_APP_API_URL}/freecourses`;
                }}
              >
                Cours gratuits
              </p>
              <p
                class="btn"
                onClick={() => {
                  window.location.href = `${process.env.REACT_APP_API_URL}/mys`;
                }}
              >
                Mes formations
              </p>
              <p
                onClick={onClick}
              >
                Fermer
              </p>
            </div>
          }
          img={true}
        />
      )}
    </>
  );
};
