 function filterComment(comment, compareCommmentId, name) {
   
  let array = [];
  for (var j = 0; j < comment.length; j++) {
     array.push(comment[j]);
  }

  const AllComment = [];

  const html = array.filter((nom) => nom.languages == "html");
  const javascript = array.filter((nom) => nom.languages == "javascript");
  const mysql = array.filter((nom) => nom.languages == "mysql");
  const reactjs = array.filter((nom) => nom.languages == "reactjs");
  const python = array.filter((nom) => nom.languages == "python");
  const css = array.filter((nom) => nom.languages == "css");

  AllComment.push({ html: html });
  AllComment.push({ javascript: javascript });
  AllComment.push({ mysql: mysql });
  AllComment.push({ reactjs: reactjs });
  AllComment.push({ python: python });
  AllComment.push({ css: css });

  const result = [];

  AllComment.forEach((com) => {
    result.push(com);
  });
 

  let exactComLanguage;

  result.forEach((res) => {
    if (res[name]) {
      exactComLanguage = res;
    }
  });
  const finalRes =  []
  exactComLanguage[name].forEach((exactComLanguages)=>{
    if(parseInt(exactComLanguages.vidId) == compareCommmentId)
    {
      finalRes.push(exactComLanguages)
    }
  })
  return finalRes
}
module.exports = { filterComment };

//let test = filterComment(param,4,"javascript");
//console.log(test)
