import React from "react";
import { IntegrateCourses } from "./integrateFormation";
import { Temoins } from "./temoignages";
import { Order } from "./CourseOrder";
// LE BLOC A PROPOS DE LA PAGE D'ACCUEIL
export const About = () => {
  return (
    <>
      <h1 id="h2">Découvrez nos formations avancées</h1>
      <div className="content-int">
        <IntegrateCourses />
        <Order />
        <Temoins/>
      </div>
      <h1 id="h2">A Propos de nous</h1>
      <div className="glob">
        <div className="class1">
          <div className="">
            <p className="left-part">
              <span>01</span>
              le premier réseau social professionnel africain dédié à la
              collaboration, la formation et l’innovation numérique !  
              <br/>
               🚀 Nous
              croyons au potentiel immense du continent africain et à la force
              de ses talents. C’est pourquoi nous avons créé une plateforme
              unique, pensée pour répondre aux besoins spécifiques des
              professionnels, des entrepreneurs, et des étudiants africains.
            </p>
            <p className="rigth-part">
            <span>02</span>
            <h1>Notre Mission </h1>
              Accompagner la communauté africaine dans son développement
              professionnel et entrepreneurial en mettant à disposition des
              outils, des formations, et un réseau adapté à ses réalités.
            </p>
            <p className="left-part">
            <span>03</span>
            <h1>Pourquoi choisir notre plateforme ? </h1>
            <h2>1️⃣ Un réseau pour tous les professionnels</h2>
              Connectez-vous avec des talents locaux et internationaux,
              développez votre réseau et trouvez des opportunités
              professionnelles adaptées à votre secteur.
            </p>
            <p className="rigth-part">
            <span>04</span>
            <h2>2️⃣ Des formations accessibles en langues locales</h2>
              Accédez à des contenus de formation en wolof, en français, et
              bientôt dans d’autres langues africaines. Notre objectif : rendre
              la formation numérique accessible à tous, sans barrière
              linguistique.
            </p>
            <p className="left-part">
            <span>05</span>
            <h2>3️⃣ Une plateforme pour apprendre et vendre</h2>
              Profitez de nos outils pour :
              <li>Vendre vos services ou produits.</li>
              <li>Publier des offres de freelance ou trouver des missions.</li>
              <li>
                {" "}
                Suivre des formations pratiques et adaptées au marché africain.
              </li>
            </p>
            <p className="rigth-part">
            <span>06</span>
            <h2>4️⃣ Accessibilité financière:</h2>
              Nous offrons des solutions abordables pour que chacun puisse se
              former et développer ses compétences, peu importe ses moyens.
            </p>
            <p className="left-part">
            <span>07</span>
            <h2>Nos Valeurs:</h2>
              <li>
                Accessibilité : Des solutions adaptées aux réalités africaines.{" "}
              </li>
              <li>
                Collaboration : Un réseau pour travailler ensemble et
                progresser.{" "}
              </li>
              <li>
                Innovation : Une vision moderne pour relever les défis locaux
                grâce au numérique.
              </li>
            </p>
            <p className="rigth-part">
            <span>08</span>
            <h2>Ce que nous vous offrons :</h2>
              <li>
                Une communauté dynamique : Rejoignez des milliers de
                professionnels, étudiants, et entrepreneurs partageant les mêmes
                ambitions.
              </li>
              <li>
                Des outils numériques puissants pour booster votre activité.{" "}
              </li>
              <li>
                Une expérience centréesur vos besoins, conçue pour les contextes
                locaux.{" "}
              </li>
            </p>
            <p className="left-part">
            <span>09</span>
            <h1>Rejoignez le mouvement </h1>
              Cette plateforme n’est pas seulement un réseau, c’est une
              opportunité de construire ensemble l’avenir du travail en Afrique.
              Que vous soyez un professionnel expérimenté, un entrepreneur
              visionnaire ou un étudiant ambitieux, cette plateforme est faite
              pour vous. 👉 Inscrivez-vous dès aujourd’hui et commencez votre
              aventure avec nous !
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
