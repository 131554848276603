import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {legacy_createStore as createStore} from 'redux'
import App from './App';
import { CookiesReducer } from './components/reducers/reducer';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux';

{/* Creation store react  */}
const red = combineReducers({
   reducer: CookiesReducer
})
const store = createStore(red)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}> 
    <App />
  </Provider>
);
