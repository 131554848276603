import "./App.css";
import { Chat } from "./components/chat";
import { Main } from "./routes/route";
import "./comcss/com.css";
import './CSs/feedbackOption.css'
function App() {
  return (
    <>
      <Main />
      <Chat />
    </>
  );
}
export default App;
