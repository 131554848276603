import React from "react";

export const ValidateOrder = ({ isTrue , form , onClickAction , order}) => {
  // Valider l inscription d'une personne aux formations
  return (
    <>
      {isTrue && (
        <>
          <div className="custom-alert" id="customAlert">
            <div className="alert-box">
              <h2> Valider l'inscription de cette personne </h2>
                {form}
                <button onClick={onClickAction}>Annuler</button>
                {order}
            </div>
          </div>
        </>
      )}
    </>
  );
};
