import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'; // Import des composants React de Swiper
import 'swiper/css'; // Styles de base de Swiper
import 'swiper/css/navigation'; // Styles pour la navigation
import 'swiper/css/pagination'; // Styles pour la pagination
import { Navigation, Pagination } from 'swiper/modules'; // Import des modules nécessaires

export const SocialMedia = () => {
  return (
    <>
      <div>
        <div className='social-media-link'>
          <h1>Suivez-nous sur les réseaux sociaux</h1>
          <div className='sml'>
            <Swiper
              modules={[Navigation, Pagination]} // Ajout des modules ici
              navigation // Activation de la navigation
              pagination={{ clickable: true }} // Activation de la pagination
              spaceBetween={50} // Espacement entre les slides
              slidesPerView={1} // Nombre de slides visibles
            >
              <SwiperSlide>
                <a href='https://www.facebook.com/profile.php?id=61567497086026'>
                  <img
                    width='96'
                    height='96'
                    src='https://img.icons8.com/color/96/facebook-new.png'
                    alt='facebook-new'
                  />
                  <p>Facebook</p>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href='https://www.instagram.com/informatiquesenwolof.com.sen/profilecard/?igsh=Mmd4cnE1cmhvZGd0'>
                  <img
                    width='94'
                    height='94'
                    src='https://img.icons8.com/3d-fluency/94/instagram-logo.png'
                    alt='instagram-logo'
                  />
                  <p>Instagram</p>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href='https://youtube.com/@informatiquesenwolof-055?si=kCCQqaqBOynnnwqY'>
                  <img
                    width='96'
                    height='96'
                    src='https://img.icons8.com/fluency/96/youtube.png'
                    alt='youtube'
                  />
                  <p>Youtube</p>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href='https://www.linkedin.com/in/informatiques-en-wolof-36237b33a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'>
                  <img
                    width='96'
                    height='96'
                    src='https://img.icons8.com/color/96/linkedin.png'
                    alt='linkedin'
                  />
                  <p>LinkedIn</p>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href='https://whatsapp.com/channel/0029VakWGZk30LKRQKXzwn3T'>
                  <img
                    width='94'
                    height='94'
                    src='https://img.icons8.com/3d-fluency/94/whatsapp-logo.png'
                    alt='whatsapp-logo'
                  />
                  <p>Communauté WhatsApp</p>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a href='https://www.tiktok.com/@informatique_en_wolof?_t=8re2CugWVGy&_r=1'>
                  <img
                    width='96'
                    height='96'
                    src='https://img.icons8.com/color/96/tiktok--v1.png'
                    alt='tiktok--v1'
                  />
                  <p>TikTok</p>
                </a>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};
