import React, { useEffect, useState } from 'react'
import axios from 'axios'
//  LES PARAMETRES SOUS LES VIDEOS COMME LES  BUTTONS COMMENTAIRES J AIMES PARTAGE
export const VideoOptions = ()=>{
    return(
        <></>
    )
//        //---------------Feddback  Informations---------------------//
//    const [isLiked,setIsLiked] = useState(false) // Verifier  si l'utilisateur a deja aimer ou pas la video`
//    const [feedForm,setFeddForm] = useState(false) // L'affichage du formulaire de commentaire
//    const [feedContent,setFeedContent] = useState('') // Contient le contenue de la commentaire
//    //----------------User  Informations---------------------//
//    const [username,setUsername] = useState('')
//    const [ name,setName  ] = useState('')
//    const [userMail,setUserMail] =  useState('')
//    const [userId,setUserId] = useState(null)
//    //----------------Videos  Informations---------------------//
//    const [langage,setLangage] = useState('') // Nom du langage de  la programmation
//    const [vidId,setVidId] = useState('') // Identifiant de la video
//    const [vidName,setVidName] = useState('') // Nom ou titre de la vidéo 
//    // L'envoi  du commentaire ou l'avis de l'utilisateur
//    const [isPostLike,setIsPostLike] = useState(false) // Savoir si on a doit envoyer au back un like ou une commentaire
//    function GetInfos(){ // Recuperer toutes les informations citées ci dessous depuis le localstorage
//        setUsername(localStorage.getItem('Prenom'))
//        setName(localStorage.getItem('Nom'))
//        setUserMail(JSON.parse(localStorage.getItem('encryptedData')).email)
//        setLangage(localStorage.getItem('languages'))
//        setVidId(localStorage.getItem('vidId'))
//        setVidName(localStorage.getItem('name'))
//        setUserId(localStorage.getItem('id'))
//    }
//    useEffect(()=>{
//        GetInfos()
//    },[])
//    // Envoi de l'avis ou commentaite de la personne
//    const submitFeedBack =  async ()=>{
//        setFeddForm(false)
//        if(feedContent == '' && !isPostLike){
//            alert("Votre commentaire n'as pas été envoyée car elle est vide !")
//        }else{
//            if(username && name && userMail && vidId && vidName  && langage){
//                let data ;
//                let url;
//                // Envoyer un like au backend si l'utilisateur a liker
//                if(isPostLike){
//                    data = {
//                        username:username,
//                        name:name,
//                        userMail:userMail,
//                        langage:langage,
//                        vidId:vidId,
//                        vidName:vidName,
//                        numero:1234567890,
//                        userId:userId
//                    }
//                    url =  `${process.env.REACT_APP_API_URL_BACK}/submit/like/post`                
//                }
//                else{
//                    // Envoyer une commentaire si l'utilisateur a liker
//                    data = {
//                        username:username,
//                        name:name,
//                        userMail:userMail,
//                        langage:langage,
//                        vidId:vidId,
//                        vidName:vidName,
//                        numero:1234567890,
//                        feedContent:feedContent,
//                        userId:userId
//                    }
//                    url =  `${process.env.REACT_APP_API_URL_BACK}/submit/feed/comment`
//                }
//                if(data  && url){
//                    axios({
//                        method:'post',
//                        url:url,
//                        data: data,
//                        withCredentials:true,
//                    })
//                    .then((res)=>{
//                        alert('Commentaire envoyée , merci  !')
//                    })
//                    .catch((err)=>{
//                        alert('Une erreur est survenue , ressayez plus tard !')
//                    })
//                }
//            }
//        }
//    }
//    return(
//        <>
//            <div className='vid-options'>
//                <div  className='vid-option'>
//                   {/*Like Bloc*/}
//                <div className='liked-bloc' >
//                        {!isLiked ? 
//                        (<img width="32" height="32" src="https://img.icons8.com/windows/32/like--v1.png" alt="like--v1" onClick={()=>{
//                            setIsLiked(true)
//                            setIsPostLike(true)
//                        }}  id='no-liked'/>):
//                        <img width="32" height="32" src="https://img.icons8.com/retro/32/hearts.png" alt="hearts" onClick={()=>{
//                            setIsLiked(false)
//                            setIsPostLike(false)
//                        }}/>                    
//                        }
//                    </div>
//                    {/*Commentaire s bloc*/}
//                    <div className='comment-bloc'>
//                    <img width="32" height="32" src="https://img.icons8.com/windows/32/comments--v1.png" alt="comments--v1" onClick={()=>{
//                        setFeddForm(true)
//                        setIsPostLike(false)
//                    }}/>         
//                    </div>
//                    {/*Bloc partage*/}
//                    <div className='share-bloc'>
//                    <img width="32" height="32" src="https://img.icons8.com/small/32/share-3.png" alt="share-3"/>                    
//                    </div>
//                </div>
//                {/*Formulaire pour donner partager son avis*/}                        
//                {feedForm  && (
//                    <div className='feed-back-div'>
//                    <div className='feed-back-div-child'>
//                    <p>Votre avis compte chez nous !</p>
//                            <textarea placeholder='Que pensez-vous de nous et de nos cours ou de notre plateforme' onChange={(e)=>setFeedContent(e.target.value)}></textarea>
//                            <button id='' onClick={submitFeedBack}>Envoyer</button>
//                    </div>
//                    </div>
//                )}
//            </div>
//        </>
    //)
}