import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
export const IntegrateCourses = () => {
  const [link, setLink] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios({
      method: "get",
      withCredentials: true,
      url: `${process.env.REACT_APP_API_URL_BACK}/submit/admin/order/check/user`,
    })
      .then((res) => {
        if (res.data.Message === "success") {
          setLink(true);
        }
      })
      .catch((err) => {
        // alert("Une erreur est survenue !");
      });
  }, []);

  function nav(link) {
    navigate(`${link}`);
  }
  return (
    <>
      <div id="training-courses">
        <div className="course-item-group">
          <div className="course-item">
            <img src="../images/new/prog.svg" alt="Node.js course" />
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                {
                  link ? nav("/mys") : nav("/mysql_node");
                }
              }}
            >
              {link ? "Suivre ma formation" : "Formation MYSQL nodeJs"}
            </a>
            {link ? (
              "Dèja achteté !"
            ) : (
              <div className="course-item-footer">
                <button>10.000 FR</button>
                <button onClick={()=>{
                document.querySelector('#optionItem').scrollIntoView({
                  behavior:'smooth',
                  block:'start'
                })
              }}>Acheter</button>
              </div>
            )}
          </div>
          <div className="course-item">
            <img src="../images/new/full.svg" alt="Full Stack course" />
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                nav("/full");
              }}
            >
              Formation complète Full stack
            </a>
            <div className="course-item-footer">
              <button>15.000 FR</button>
              <button onClick={()=>{
                document.querySelector('#optionItem').scrollIntoView({
                  behavior:'smooth',
                  block:'start'
                })
              }}>Acheter</button>
              </div>
          </div>
        </div>
        <div className="course-item-group">
          <div className="course-item">
            <img src="../images/new/front.svg" alt="React JS course" />
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                nav("/html_react");
              }}
            >
              Formation HTML CSS REACT JS
            </a>
            <div className="course-item-footer">
              <button>8.500 FR</button>
              <button onClick={()=>{
                document.querySelector('#optionItem').scrollIntoView({
                  behavior:'smooth',
                  block:'start'
                })
              }}>Acheter</button>
              </div>
          </div>
          <div className="course-item">
            <img src="../images/new/back.svg" alt="JavaScript course" id='last-child' />
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                nav("/mysql_js");
              }}
            >
              <br />
              Formation Backend
            </a>
            <div className="course-item-footer">
              <button>10.000 FR</button>
              <button onClick={()=>{
                document.querySelector('#optionItem').scrollIntoView({
                  behavior:'smooth',
                  block:'start'
                })
              }}>Acheter</button>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};
