import React from "react";
import { UnikFormation } from "./unikFormationPage";

export const Full = () => {
  const nom = "Formation complète full stack";
  const prix = "15.000 Fr";
  const icon = "../images/img.svg";
  const imag = "../images/not.png";
  const description = `Deviens un développeur full stack complet en maîtrisant le
            front-end et le back-end avec les technologies les plus demandées.
            🎯 Ce que tu apprendras : 
            Front-end : Création d’interfaces utilisateurs dynamiques avec HTML,
            CSS, JavaScript et React.js
            Back-end : Développement d’applications robustes avec Node.js et
            gestion de bases de données avec MySQL
            API REST, sécurité et optimisation des performances`;

  const img = "../images/computer.png";

  return (
    <>
      <UnikFormation
        nom={nom}
        prix={prix}
        programme={
          <ul>
            <li>
              <img src={icon} alt="pic-video" /> Introduction au développement web
            </li>
            <li>
              <img src={icon} alt="pic-video" /> HTML : Structuration de contenu
            </li>
            <li>
              <img src={icon} alt="pic-video" /> CSS : Stylisation et mise en page
            </li>
            <li>
              <img src={icon} alt="pic-video" /> JavaScript : Concepts fondamentaux
            </li>
            <li>
              <img src={icon} alt="pic-video" /> DOM Manipulation et événements
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Introduction à React.js : Composants et props
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Gestion de l'état avec useState et useEffect
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Routing avec React Router
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Introduction à Node.js et Express.js
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Création d'API RESTful
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Gestion des bases de données avec MySQL
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Authentification et sécurité des applications
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Optimisation des performances web
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Déploiement d'applications web
            </li>
            <li>
              <img src={icon} alt="pic-video" /> Projet final : Création d'une application full stack
            </li>
          </ul>
        }
        description={description}
        img={img}
        imag={imag}
        aper={null}
      />
    </>
  );
};
