import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Formations } from "../components/formations";
import { Home } from "../components/home";
import { Mysql_node } from "../components/mysql_node";
import { Html_react } from "../components/react_html";
import { Mysql_js } from "../components/mysql_js";
import { Full } from "../components/full";
import { InscriptionForm } from "../components/inscriptionForm";
import { Login } from "../components/login";
import { MyEspace } from "../components/MyEspace";
import { Unautthorized } from "../components/auth";
import { SendCookie } from "../components/actions/action";
import { useDispatch } from "react-redux";
import { Coming } from "../components/coming";
import { MysqlForm } from "../components/mysqlFormation";
import { InscriptionFormAdmin } from "../components/adminInsciptio";
import { AdminLogin } from "../components/adminLogin";
import { AdminDash } from "../components/adminDash";
import { FreeCourses } from "../components/freecourses";
import { ForgetPass } from "../components/passForget";
import UnikPLayVideo from "../components/unikPlayVideo";

export const Main = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); // La variable de l admin pour donner l'accès
  const id = localStorage.getItem("id"); // Recuprer l id de l user depuis le local storage
  let email = JSON.parse(localStorage.getItem("maskedData"));// Recuprer l email masqué depuis le local storage
  if (email)  email = email.email  
  else  email = ''
  const dispatch = useDispatch();
  //Verifier si l user est connecté ou pas
  useEffect(() => {
    const CheckAuth = async () => {
      if (id && email) {
        await axios({
          method: "get",
          withCredentials: true,
          url: `${process.env.REACT_APP_API_URL_BACK}/check/auth/${email}/${id}`,
        })
          .then((res) => {
            if (res.data.Message === "success" || res.status === 200) {
              setIsConnected(true);
              dispatch(SendCookie({ Authorization: "True" }));
            } else {
              setIsConnected(false);
              dispatch(SendCookie({ Authorization: "False" }));
            }
          }).catch((err)=>'')
}};
CheckAuth();
// Verifier si la personne connectée est l admin oubien
const CheckAuthAdmin = async () => {
        await axios({
          method: "get",
          withCredentials: true,
          url: `${process.env.REACT_APP_API_URL_BACK}/submit/check/admin` })
          .then((res) => {if (res.data.Message === "success" || res.status === 200) setIsAdmin(true)})
          .catch((error) => setIsAdmin(false)
        )};
    CheckAuth();
    CheckAuthAdmin();
  },[]);
  return (
    <Router>
      <Routes>
        <Route path="/formations" element={<Formations />} />
        <Route path="*" element={<Home />} />
        <Route path="/mysql_node" element={<Mysql_node />} />
        <Route path="/html_react" element={<Html_react />} />
        <Route path="/mysql_js" element={<Mysql_js />} />
        <Route path="/regester/login" element={<InscriptionForm />} />
        <Route path="/regester/signin" element={<Login />} />
        <Route path="/full" element={<Full />} />
        <Route path="/espace" element={isConnected ? <MyEspace /> : <Unautthorized />}/>     {/*donner accès à l user s'il est connecté*/}
        <Route path="/singlevid"element={isConnected ? <UnikPLayVideo /> : <Unautthorized />}/>  {/*  donner accès à l user s'il est connecté */}
        <Route  path="/freecourses"  element={<FreeCourses /> }/>
        <Route path="/coming" element={<Coming />} />
        <Route path="/regester/forgetpass" element={<ForgetPass />} />
        <Route path="/mys" element={<MysqlForm />} />
        <Route    path="/regester/login/admin/create/account"    element={<InscriptionFormAdmin />}  />
        <Route  path="/regester/login/admin/login/account"  element={<AdminLogin />}/>
        <Route  path="/admin/dashboard/infos"  element={isAdmin ? <AdminDash /> : <Unautthorized />}/>
      </Routes>
    </Router>
  );
};
