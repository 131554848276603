import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { filterComment } from "./Options/filterComment";
import moment from "moment";
import { VideoOptions } from "./VideosOptions";
// Lire une vidéo sur une page dediée
const UnikPLayVideo = () => {
  //---------------video variables------------------------------//
  const video = useSelector((state) => state.reducer.media); // Recupérer la video que l'utilisateur  veut regarder depuis le reducer
  let  vidTitle; //Contient le titre de la video que l'utilisateur veut regarder en mode unique
  const langage =  localStorage.getItem("languages"); //  langage  du cours de la video  ex: html  ou css 
  //---------------Commentaires variables------------------------------//
  const getComment = useSelector((state) => state.reducer.comment); // recupérer tous les commentaites de la vidéo depuis le reducer
  const [userComment, setUserComment] = useState([ ]); //Contient les commentaire de l'utilisateur au moment ou il commente pour l afficher directement
  const [userCommentField, setUserCommentFiled] = useState(""); //Champ  commentaire de l'utilisateur du formulaire pour poster une commentaire
  const [isBlocComment, setIsBlocComment] = useState(true); // Afficher le commentaire de l'utilisateur une fois qu'il a commenté
  const [commentdata, setCommentdata] = useState(null); // CONTIENT TOUS LES COMMENTAIRES FILTREES
  //---------------user variables------------------------------//
  const [nom, setNom] = useState("");  // Contient le nom de l'utilisateur pour envoyer sa commentaire
  const [prenom, setPrenom] = useState("");// Contient le nom de l'utilisateur pour envoyer sa commentaire
  const itemVideo =  localStorage.getItem("name");  // contient le nom de la vidéo
  const userId = localStorage.getItem("id");   // L'identifiant de l'utilisateur 
//----------------------------------------------------------------//
  let cours , id  , course;
//
  useEffect(()=>{
    setPrenom(localStorage.getItem("Prenom"));
    setNom(localStorage.getItem("Nom"));
    const  exactComment =  filterComment(getComment,id,langage)  // filtrer l ensemble des  commentaire et recuperer que les commentaires de la video correspondante
    setCommentdata(exactComment)
  },[])
  //
   if (video && video[0] && video[0].cours ) {
    vidTitle=video[0].name; // Recuperer le titre de la video 
    cours = video[0].cours;
    course = cours.filter((cour) => cour.names == itemVideo);       //  Filtrer les  videos en fonction du nom de la video que l utilisateur a choisi
    localStorage.setItem('vidId',course[0].id)
    if(course[0] && course[0].id)     id = course[0].id;
  } else {
    return (
      <>
        <div>
          <p className="error">
            Retournez à la page précédente et recliquez sur le button voir la
            vidéo...
          </p>
        </div>
      </>
    );
  }
  const handleComment = async () => {
    if (video[0].name && id && userId && prenom && nom) {
      if (userCommentField.length == 0 || userCommentField.length == null || userCommentField == "") {       //  Verifier si l'utilisateur n'as pas envoyer une commentaire vide
        alert("Commentaire vide !");
      } else {
        // j envoie le numero sinon la verification check user va bloquer la requete
        axios({
          method: "post",
          withCredentials: true,
          url: `${process.env.REACT_APP_API_URL_BACK}/submit/comment`,
          data: 
          {
            comment: userCommentField,name: vidTitle,
            id: id,item: itemVideo,posterId: userId,
            numero: "1963-24",prenom: prenom,nom: nom,
          },
        })
          .then((res) => {
            setUserComment([...userComment, res.data.comment]);
            setIsBlocComment(true);
          })
          .catch((err) => {
            console.log(err)
            alert('Une erreur est survenue !')
          });
      }
    }
  };

  return (
    <>
      {course &&
        cours.length > 0 &&
        course.map((datas) => {
          return (
            <>
              <div className="main-bloc">
                <div className="contentOne">
                  <video id="maVideo" controls controlsList="nodownload" className="fullscreen-video">
                    <source  className="vid"  type="video/mp4"  src={`${process.env.REACT_APP_API_URL_BACK_NO}/videos/${datas.names}`} />
                  </video>
                  <div className="title">
                    <h1>{datas.titres}</h1>
                    <p>{datas.soustitres}</p>
                    <VideoOptions />
                  </div>
                </div>
                <div className="contentTwo">
                  <p>Description</p>
                  <article>{datas.descriptions}</article>
                  <div className="comments">
                    <p>Commentaires</p>
                    {userComment.map((userComment) => {
                      return (
                        <div className="class-comment">
                          <>
                          {/* Afficher le commentaire  de l``utilisateur   a l 'instant ou il a commenté */}
                            {isBlocComment && (
                              <>
                                <div className="profil">
                                  <p id="prenom">{prenom} {""} {nom}{" "}</p>
                                  <p>.maintenant</p>
                                </div>
                                <p id="comment">{userComment}</p>
                              </>
                            )}
                          </>
                        </div>
                      );
                    })}
                    {/* Afficher  tous les commentaires */}
                    {commentdata && commentdata.length>0 && commentdata.map((feeds) => {
                      return (
                        <div className="class-comment">
                          <>
                              <div className="profil">
                                <p id="prenom">
                                  {feeds.prenom} {""} {feeds.nom}{" "}
                                </p>
                                <p>{moment(feeds.createdAt).format('DD/MM/YY   HH: mm: ss')}</p>
                              </div>
                              <p id="comment">{feeds.comments}</p>
                          </>
                        </div>
                      );
                    })}
                    <div>
                      <label>Laissez un commentaires</label>
                      <textarea placeholder="Laissez un commentaire" required onChange={(e) => setUserCommentFiled(e.target.value)}></textarea>
                      <input type="submit" onClick={handleComment} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </>
  );
};

export default UnikPLayVideo;
