import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Alert } from "./alert";
import { Homeheader } from "./homeHeader";
import { useNavigate } from "react-router-dom";
import { SendComment } from "./actions/action";
import { SendMedia } from "./actions/action";
import { useDispatch } from "react-redux";
import { IntegrateCourses } from "./integrateFormation";
import { SocialMedia } from "./social.media";
export const MysqlForm = () => {
  const [id,setId] = useState(null)
  const [data, setData] = useState(null);
  const [link,setLink] = useState(false)
  const [html, setHtml] = useState(null);
  const [css, setCss] = useState(null);
  const [javascript, setJavascript] = useState(null);
  const [nodejs, setNodejs] = useState(null);
  const [mysql, setMysql] = useState(null);
  const [python, setPython] = useState(null);
  const [reactjs, setReactjs] = useState(null);
  const [display, setDisplay] = useState("");
  const videoRef = useRef(null)
  const navigate =  useNavigate()
  const dispatch = useDispatch()


  let nom;
  let video = [];
  const array = [html, css, javascript, mysql, python, reactjs, nodejs];

  const Dispathed = async (name,langage) => {

    localStorage.setItem("name", name);
    localStorage.setItem("languages", langage);

    for (let i = 0; i < languages.length; i++) {
        if (languages[i].id === display) {
            nom = languages[i].id.toLowerCase();
            break;
        }
      }
    for (const arr of array) {
        if (arr.name === nom) {
            video.push(arr);
        }
    }
    await dispatch(SendMedia(video));
    navigate('/singlevid')
}

  useEffect(() => {
    setId(localStorage.getItem('ID'))
    axios({
      method: "get",
      withCredentials: true,
      data: { numero: "61781" },
      url: `${process.env.REACT_APP_API_URL_BACK}/submit/${id}/video/get`,
    })
    .then((res) => {
      setData(res.data);
      dispatch(SendComment(res.data.datas[7].commentaires))

      for (var i = 0; i < res.data.datas.length; i++) {
        switch (res.data.datas[i].name) {
          case "html":
            setHtml(res.data.datas[i]);
            break;
          case "css":
            setCss(res.data.datas[i]);
            break;
          case "javascript":
            setJavascript(res.data.datas[i]);
            break;
          case "nodejs":
            setNodejs(res.data.datas[i]);
            break;
          case "reactjs":
            setReactjs(res.data.datas[i]);
            break;
          case "mysql":
            setMysql(res.data.datas[i]);
            break;
          case "python":
            setPython(res.data.datas[i]);
            break;
          default:
            console.log("");
        }
      }
    })
      .catch((err) => {
        //alert('Une erreur est survenue !')
      });
  }, []);

  useEffect(() => {
    axios({
      method: "get",
      withCredentials: true,
      url: `${process.env.REACT_APP_API_URL_BACK}/submit/admin/order/check/user`,
    })
      .then((res) => {
        if (res.data.Message === "success") {
          setLink(true);
        }
      })
      .catch((err) => {
        //alert("Une erreur est survenue !");
      });
  }, []);
  const languages = [
    {
      id: "Html",
      name: "HTML",
      imgSrc: "../images/lang/html.png",
      bgColor: "#e34c26",
    },
    {
      id: "Css",
      name: "CSS",
      imgSrc: "../images/lang/css.png",
      bgColor: "#264de4",
    },
    {
      id: "Javascript",
      name: "JavaScript",
      imgSrc: "../images/lang/javascript.png",
      bgColor: "#f0db4f",
      textColor: "#323330",
    },
    {
      id: "Nodejs",
      name: "Node.js",
      imgSrc: "../images/lang/nodejs.png",
      bgColor: "#68a063",
    },
    {
      id: "Python",
      name: "Python",
      imgSrc: "../images/lang/python.png",
      bgColor: "#306998",
    },
    {
      id: "React",
      name: "React",
      imgSrc: "../images/lang/reactjs.png",
      bgColor: "#61dafb",
      textColor: "#282c34",
    },
    {
      id: "Mysql",
      name: "MySQL",
      imgSrc: "../images/lang/mysql.png",
      bgColor: "#f29111",
    },
  ];

  const LanguageCard = ({ id, name, imgSrc, bgColor, textColor = "white" }) => {
    return (
      <div
        className="language-card"
        style={{ backgroundColor: bgColor, id, color: textColor }}
        onClick={() => {
          setDisplay(id);
        }}
      >
        <img src={imgSrc} alt={name} />
        <span className="language-name" id={id}>
          {name}
        </span>
        <span className="icon-plus">+</span>
      </div>
    );
  };

  return (
    <>
      <Homeheader props={false} />
      <>
        <div className="language-container">
          {languages.map((lang) => (
            <LanguageCard
              key={lang.name}
              name={lang.name}
              imgSrc={lang.imgSrc}
              bgColor={lang.bgColor}
              textColor={lang.textColor}
              id={lang.id}
            />
          ))}
        </div>
      </>
      {link ? (
        <>
          {display == "Html" ? (
            <>
              <p className="length" style={{ textAlign: "center" }}>
                {html.cours ? (
                  <p>
                    Nombre de cours pour ce langage (Cours HTML) pour le moment:{" "}
                    <span>0{html.cours.length}</span> <br /> restez connecté
                    pour d'autres cours qui viennent{" "}
                  </p>
                ) : (
                  ""
                )}
              </p>
              {html.cours &&
                html.cours.length > 0 &&
                html.cours.map((datas) => {
                  return (
                    <>
                      <div className="main-bloc">
                        <div className="contentOne">
                          <video
                            id="maVideo"
                            controls
                            controlsList="nodownload"
                            ref={videoRef}
                            className="fullscreen-video"
                          >
                            <source
                              className="vid"
                              type="video/mp4"
                              src={`${process.env.REACT_APP_API_URL_BACK_NO}/videos/${datas.names}`}
                            />
                          </video>
                          <div className="title">
                            <h1>{datas.titres}</h1>
                            <p>{datas.soustitres}</p>
                          </div>
                        </div>
                        <div className="contentTwo">
                          <button id="details" onClick={() => Dispathed(datas.names,'html')}>
                            Voir la video en détaille ici
                          </button>
                          <p>Description</p>
                          <article>{datas.descriptions}</article>
                          <div className="comments">
                            <p>Commentaires</p>
                            <div>
                              {/*<label>Laissez un commentaires</label>*/}
                              <textarea>
                                Commentaires non disponibles sur cette page, voir la vidéo en détaille
                              </textarea>
                              {/*<input type="submit" />*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </>
          ) : (
            ""
          )}
          <>
            {display === "Css" && (
              <>
                <p className="length" style={{ textAlign: "center" }}>
                  {css.cours ? (
                    <p>
                      Nombre de cours pour ce langage (Cours CSS) :{" "}
                      <span>0{css.cours.length}</span> <br /> pour le moment
                      restez connecté pour d'autres cours qui viennent
                    </p>
                  ) : (
                    ""
                  )}
                </p>
                {css.cours &&
                  css.cours.length > 0 &&
                  css.cours.map((datas) => {
                    return (
                      <>
                        <div className="main-bloc">
                          <div className="contentOne">
                            <video
                              id="maVideo"
                              controls
                              controlsList="nodownload"
                              ref={videoRef}
                              className="fullscreen-video"
                            >
                              <source
                                className="vid"
                                type="video/mp4"
                                src={`${process.env.REACT_APP_API_URL_BACK_NO}/videos/${datas.names}`}
                              />
                            </video>
                            <div className="title">
                              <h1>{datas.titres}</h1>
                              <p>{datas.soustitres}</p>
                            </div>
                          </div>
                          <div className="contentTwo">
                          <button id="detail" onClick={() => Dispathed(datas.names,'css')}>
                              Voir la video en détaille ici
                            </button>
                            <p>Description</p>
                            <article>{datas.descriptions}</article>
                            <div className="comments">
                              <p>Commentaires</p>
                              <div>
                                {/*<label>Laissez un commentaires</label>*/}
                                <textarea>
                                Commentaires non disponibles sur cette page, voir la vidéo en détaille
                                </textarea>
                                {/*<input type="submit" />*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </>
            )}
          </>

          {display === "Javascript" && (
            <>
              <p className="length" style={{ textAlign: "center" }}>
                {javascript.cours ? (
                  <p>
                    Nombre de cours pour ce langage (Cours JavaScript) :{" "}
                    <span>0{javascript.cours.length}</span> <br /> pour le
                    moment restez connecté pour d'autres cours qui viennent{" "}
                  </p>
                ) : (
                  ""
                )}
              </p>
              {javascript.cours &&
                javascript.cours.length > 0 &&
                javascript.cours.map((datas) => {
                  return (
                    <>
                      <div className="main-bloc">
                        <div className="contentOne">
                          <video
                            id="maVideo"
                            controls
                            controlsList="nodownload"
                            ref={videoRef}
                            className="fullscreen-video"
                          >
                            <source
                              className="vid"
                              type="video/mp4"
                              src={`${process.env.REACT_APP_API_URL_BACK_NO}/videos/${datas.names}`}
                            />
                          </video>
                          <div className="title">
                            <h1>{datas.titres}</h1>
                            <p>{datas.soustitres}</p>
                          </div>
                        </div>
                        <div className="contentTwo">
                        <button id="detail" onClick={() => Dispathed(datas.names,'javascript')}>
                        Voir la video en détaille ici
                          </button>
                          <p>Description</p>
                          <article>{datas.descriptions}</article>
                          <div className="comments">
                            <p>Commentaires</p>
                            <div>
                              {/*<label>Laissez un commentaires</label>*/}
                              <textarea>
                              Commentaires non disponibles sur cette page, voir la vidéo en détaille
                              </textarea>
                              {/*<input type="submit" />*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </>
          )}
          {display == "Nodejs" && (
            <>
              <p className="length" style={{ textAlign: "center" }}>
                {nodejs.cours ? (
                  <p>
                    Nombre de cours pour ce langage (Cours NodeJS) :{" "}
                    <span>0{nodejs.cours.length}</span> <br /> pour le moment
                    restez connecté pour d'autres cours qui viennent{" "}
                  </p>
                ) : (
                  ""
                )}
              </p>
              {nodejs.cours &&
                nodejs.cours.length > 0 &&
                nodejs.cours.map((datas) => {
                  return (
                    <>
                      <div className="main-bloc">
                        <div className="contentOne">
                          <video
                            id="maVideo"
                            controls
                            controlsList="nodownload"
                            ref={videoRef}
                            className="fullscreen-video"
                          >
                            <source
                              className="vid"
                              type="video/mp4"
                              src={`${process.env.REACT_APP_API_URL_BACK_NO}/videos/${datas.names}`}
                            />
                          </video>
                          <div className="title">
                            <h1>{datas.titres}</h1>
                            <p>{datas.soustitres}</p>
                          </div>
                        </div>
                        <div className="contentTwo">
                        <button id="detail" onClick={() => Dispathed(datas.names,'nodejs')}>
                        Voir la video en détaille ici
                          </button>
                          <p>Description</p>
                          <article>{datas.descriptions}</article>
                          <div className="comments">
                            <p>Commentaires</p>
                            <div>
                              {/*<label>Laissez un commentaires</label>*/}
                              <textarea>
                              Commentaires non disponibles sur cette page, voir la vidéo en détaille
                              </textarea>
                              {/*<input type="submit" />*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </>
          )}
          {display == "Mysql" && (
            <>
              <p className="length" style={{ textAlign: "center" }}>
                {mysql.cours ? (
                  <p>
                    Nombre de cours pour ce langage (Cours MYSQL) :{" "}
                    <span>0{mysql.cours.length}</span> <br /> pour le moment
                    restez connecté pour d'autres cours qui viennent{" "}
                  </p>
                ) : (
                  ""
                )}
              </p>
              {mysql.cours &&
                mysql.cours.length > 0 &&
                mysql.cours.map((datas) => {
                  return (
                    <>
                      <div className="main-bloc">
                        <div className="contentOne">
                          <video
                            id="maVideo"
                            controls
                            controlsList="nodownload"
                            ref={videoRef}
                            className="fullscreen-video"
                          >
                            <source
                              className="vid"
                              type="video/mp4"
                              src={`${process.env.REACT_APP_API_URL_BACK_NO}/videos/${datas.names}`}
                            />
                          </video>
                          <div className="title">
                            <h1>{datas.titres}</h1>
                            <p>{datas.soustitres}</p>
                          </div>
                        </div>
                        <div className="contentTwo">
                        <button id="detail" onClick={() => Dispathed(datas.names,'mysql')}>
                        Voir la video en détaille ici
                          </button>
                          <p>Description</p>
                          <article>{datas.descriptions}</article>
                          <div className="comments">
                            <p>Commentaires</p>
                            <div>
                              {/*<label>Laissez un commentaires</label>*/}
                              <textarea>
                              Commentaires non disponibles sur cette page, voir la vidéo en détaille
                              </textarea>
                              {/*<input type="submit" />*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </>
          )}
          {display === "React" && (
            <>
              <p className="length" style={{ textAlign: "center" }}>
                {reactjs.cours ? (
                  <p>
                    Nombre de cours pour ce langage (Cours React JS):{" "}
                    <span>0{reactjs.cours.length}</span> <br /> pour le moment
                    restez connecté pour d'autres cours qui viennent{" "}
                  </p>
                ) : (
                  "Nombre de cours 0: aucun cours pour le moment"
                )}
              </p>
              {reactjs.cours &&
                reactjs.cours.length > 0 &&
                reactjs.cours.map((datas) => {
                  return (
                    <>
                      <div className="main-bloc">
                        <div className="contentOne">
                          <video
                            id="maVideo"
                            controls
                            controlsList="nodownload"
                            ref={videoRef}
                            className="fullscreen-video"
                          >
                            <source
                              className="vid"
                              type="video/mp4"
                              src={`${process.env.REACT_APP_API_URL_BACK_NO}/videos/${datas.names}`}
                            />
                          </video>
                          <div className="title">
                            <h1>{datas.titres}</h1>
                            <p>{datas.soustitres}</p>
                          </div>
                        </div>
                        <div className="contentTwo">
                        <button id="detail" onClick={() => Dispathed(datas.names,'reactjs')}>
                        Voir la video en détaille ici
                          </button>
                          <p>Description</p>
                          <article>{datas.descriptions}</article>
                          <div className="comments">
                            <p>Commentaires</p>
                            <div>
                              {/*<label>Laissez un commentaires</label>*/}
                              <textarea>
                              Commentaires non disponibles sur cette page, voir la vidéo en détaille
                              </textarea>
                              {/*<input type="submit" />*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </>
          )}
          {display == "Python" && (
            <>
              <p className="length" style={{ textAlign: "center" }}>
                {python.cours ? (
                  <p>
                    Nombre de cours pour ce langage (Cours Python) :{" "}
                    <span>0{python.cours.length}</span> <br /> pour le moment
                    restez connecté pour d'autres cours qui viennent{" "}
                  </p>
                ) : (
                  "Nombre de cours 0,aucun cours pour le moment"
                )}
              </p>

              {python.cours &&
                python.cours.length > 0 &&
                python.cours.map((datas) => {
                  return (
                    <>
                      <div className="main-bloc">
                        <div className="contentOne">
                          <video
                            id="maVideo"
                            controls
                            controlsList="nodownload"
                            ref={videoRef}
                            className="fullscreen-video"
                          >
                            <source
                              className="vid"
                              type="video/mp4"
                              src={`${process.env.REACT_APP_API_URL_BACK_NO}/videos/${datas.names}`}
                            />
                          </video>
                          <div className="title">
                            <h1>{datas.titres}</h1>
                            <p>{datas.soustitres}</p>
                          </div>
                        </div>
                        <div className="contentTwo">
                        <button id="detail" onClick={() => Dispathed(datas.names,'python')}>
                        Voir la video en détaille ici
                          </button>
                          <p>Description</p>
                          <article>{datas.descriptions}</article>
                          <div className="comments">
                            <p>Commentaires</p>
                            <div>
                              {/*<label>Laissez un commentaires</label>*/}
                              <textarea>
                              Commentaires non disponibles sur cette page, voir la vidéo en détaille
                              </textarea>
                              {/*<input type="submit" />*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </>
          )}
          <div className="content-int">
          <IntegrateCourses/>
          <SocialMedia / >
          </div>
        </>
      ) : (
        <>
          {}
          <Alert
            message={
              "Si vous n'êtes pas connecté,connectez-vous d'abord sinon , patientez"
            }
            titre="Chargement,veuillez patienté........ "
            sous={""}
            dis={true}
            button={
              <button
                class="close-btn"
                onClick={() => {
                  navigate(`/regester/signin`);
                }}>
                Me connecter
              </button>
            }
            img={false}
          />
        </>
      )}
    </>
  );
};
