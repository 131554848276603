import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Alert } from "./alert";
import { useNavigate } from "react-router-dom";
// Acheter une formation (formulaire)
export const Order = () => {
  //-------------------PERMISSION DE L'UTILISATEUR-------------------------//
  const permission = useSelector((state) => state.reducer.cookie);  //    Recuperer la permission au niveau du state reducer
  const [isGranted, setIsGranted] = useState(""); //  Contient la  permission  de l'utilisateur s'il est connecté ou non
//-------------AFFICHAGE ET ANIMATION -------------------------------------//
  const [display, setDisplay] = useState(false); // Affichage du formulaire d ' achat de formation de base false
  const [anim, setAnim] = useState([]);
  const [then, setThen] = useState(false); //  Affichage du message de succés une fois la demande de formation envoyée
  const [start, setStart] = useState(false);  // DEMARRER LE CHARGEMENT (L'ANIMATION)
  const [isClose, setIsClose] = useState(false);  // FERMER L'ALERTE
  //----------INFORMATIONS PERSONNE ---------------------------------//
  const [mail, setMail] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [numero, setNumero] = useState("");
  const [choice, setChoice] = useState("full"); // Contient la formation que l utilisateur veut acheter , son choix
  //-------------ERREUR BLOC--------------------------//
  const [h1,setH1] = useState('Erreur')
  const h2 = 'Une erreur est survenue '
  const [err,setErr] = useState('Actualisez la page et ressayez dans un instant !')
  // ---------------------------------------------------------------------------------------//
  const navigate = useNavigate()
  function nav(link){
    navigate(`${link}`)
  }
  const handleSubmit = () => {
    setStart(true);
    axios({
      method: "post",
      data: {choice: choice,email: mail,prenom: prenom,nom: nom,numero: numero,},
      url: `${process.env.REACT_APP_API_URL_BACK}/order`,
      withCredentials: true,
    })
      .then(() => {
        setTimeout(() => setThen(true), 1500);
        setAnim(false)
        setStart(false)
      })
      .catch((err) => {
        setDisplay(true);
        setIsClose(true)
        //
        if(err.response.status==500){
          setH1('........')
          setErr('Vous avez dèja envoyez une demande de cette formation !')
        }
        if (
          err.response &&
          err.response.data.err &&
          err.response.data.err.name &&
          err.response.data.err.name === "TokenExpiredError"
        ) {
          setErr('Session de connexion expirée reconnectez-vous !')
          nav('/regester/signin');
        }
      });
  };
  // ANIMATION DE CHARGEMENT ENCOURS
  const array = [];
  useEffect(() => {
      if (start && then==false) {  // Demarrer l'animation de chargement lors de l'envoi de la demande de formation
        for (let i = 0; i <= 7; i++) {
          setTimeout(() => {
            array.push(i);
            setAnim([...array]);
          }, 1000 * i);
        }
      }
      return () => {
        setStart(false);
        setAnim([]);
      };
    },
    [start],[then]
  );
  // RECUPERER INFORMATIONS DE LA PERSONNE
  useEffect(() => {
    const nom = localStorage.getItem("Nom");
    const prenom = localStorage.getItem("Prenom");
    ///------------------------///
    if(JSON.parse(localStorage.getItem('encryptedData'))){
      const mail =  JSON.parse(localStorage.getItem('encryptedData')).email;      
      setMail(mail);
      const numero = JSON.parse(localStorage.getItem('encryptedData')).contact;
      setNumero(numero);
    }
    //
    setNom(nom);
    setPrenom(prenom);
    //
    setIsGranted(permission);  // Stocker la permission de l'utilisateur dans une variable
    if (isGranted.Authorization == "True")  setDisplay(true);  //  Afficher le formulaire d'achat si l'utilisateur a connecté
  });
  return (
    <div id="or">
      <>
        <Alert message={err} titre={h1} sous={h2} dis={isClose} button={
            <button class="close-btn" onClick={() =>   setIsClose(false)}> Fermer</button>
          }/>
        {/*Si l'utilisateur est connecté afficher cette partie */}
        {display && (
          <>
            <div className="animate">
              {/*ANIMATION DE CHARGEMENT..........*/}
                {!then && anim && anim.length > 0 && (
                    <>
                    <p style={{ textAlign: "center" }}>Envoi encours</p>
                      {anim.map((anim) => {
                          return (
                                <>
                                    <h6 id="id" key={anim}>o</h6>
                                </>
                              );
                            })
                      }
                    </>
                )}
            </div>
            {/*FIN ANIMATION*/}
            {/*Afficher le  formulaire */}
            {!then && (
              <div className="order-form">
                <h2 id="optionItem">Vous voulez acheter une formation ?</h2>
                <div className="part-2">
                      <div className="option-item">
                      <div className="option-item-img">
                          <img width="48" height="48" src="https://img.icons8.com/color/48/html-5--v1.png" alt="html-5--v1"/> 
                          <img width="48" height="48" src="https://img.icons8.com/fluency/48/css3.png" alt="css3"/>
                          <img width="48" height="48" src="https://img.icons8.com/color/48/javascript.png" alt="javascript"/>
                          <img width="48" height="48" src="https://img.icons8.com/color/48/react-native.png" alt="react-native"/>
                      </div>
                      <p>Formation front-end 8.500 fr</p>
                      </div>
                      <div className="option-item" >
                      <div className="option-item-img">
                      <img width="48" height="48" src="https://img.icons8.com/fluency/48/node-js.png" alt="node-js"/>
                      <img width="48" height="48" src="https://img.icons8.com/fluency/48/mysql-logo.png" alt="mysql-logo"/>                          
                      <img width="48" height="48" src="https://img.icons8.com/color/48/javascript.png" alt="javascript"/>
                        <img width="48" height="48" src="https://img.icons8.com/color/48/react-native.png" alt="react-native"/>
                      </div>
                      <p>Formation back-end 10.000 fr</p>
                      </div>
                      <div className="option-item">
                      <div className="option-item-img">
                          <img width="48" height="48" src="https://img.icons8.com/color/48/javascript.png" alt="javascript"/>
                          <img width="48" height="48" src="https://img.icons8.com/color/48/react-native.png" alt="react-native"/>
                          <img width="48" height="48" src="https://img.icons8.com/fluency/48/node-js.png" alt="node-js"/>
                          <img width="48" height="48" src="https://img.icons8.com/fluency/48/mysql-logo.png" alt="mysql-logo"/>            
                      </div>
                      <p>Formation Full stack 15.000 fr</p>
                      </div>
                </div>
                <div className="part-1">
                  <h3>Choisissez la ici !</h3>
                  <figure>
                    <select onChange={(e) => setChoice(e.target.value)}>
                      <option value="back">Formation back-end node js (10.000 fr)</option>
                      <option value="front">Formation front-end HTML CSS REACT JS (8.500 fr){" "}</option>
                      <option value="full">Formation complète Full stack (15.000 fr){" "}</option>
                    </select>
                  </figure>
                  <button id="" onClick={handleSubmit}>Envoyer la demande</button>
                </div>
              </div>
            )}
            {/*Afficher le message de succes apres une demande de formation*/}
            {then && (
              <div className="success-form">
                  <div className="success-child">
                  <h3>Demande envoyée avec succès !<br/>
                        Nous vous contacterons pour la finalisation <br/> de votre inscription et paiement
                      </h3>
                      <img src="../images/suc.png" alt="suc-img" width='10%' />
                      <button id="" onClick={() => setThen(false)}>Acheter une nouvelle formation</button>
                  </div>
              </div>
            )}
          </>
        )}
        {/*Si l'utilisateur n est pas connecte ,  afficher cette partie*/}
        {!display && (
          <div className="orderDiv">
            <h3>Connectez-vous pour acheter une formation !</h3>
            <a href="/regester/signin">Cliquez ici pour vous connecter</a>
          </div>
        )}
      </>
    </div>
  );
};
