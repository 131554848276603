import React from "react";
import { Homeheader } from "./homeHeader";
import { Form } from "./form";
import { Order } from "./CourseOrder";
//   Afficher une formation seule sur sa propre page dediée
export const  UnikFormation = ({ nom, prix, description, programme, img, imag, aper,}) => {
  return (
    <>
      <Homeheader props={false} />  {/*Pour eviter que certains liens du menu de navigation comme a propos et contact ne s affiche pas sur cette  page */}
      <div>
        <div className="product-content">
          <div className="product-content-child">
            <h1>{nom}</h1>
            <img src={img} alt="pic" id="pic" />  {/*Image representant la formation !*/}
            <h2>Description</h2>
            <p className="new-line">{description}</p>
            <button id="price">{prix}</button>
          </div>
          <div className="product-content-child" id="product-child">
            <a href="" onClick={(e) => e.preventDefault()}>
              {aper ? "Apercu du cours" : "Apercu du cours non disponible pour le moment"}  {/*Verifier si l apercu du cours est disponible ou pas !*/}
            </a>
            {/*Afficher le lien de la video youtube si  l image representant l apercu non disponible d une formation est nulle nb:  different de img  qui est a la ligne 14 */}
            {imag !== null ? ( <img id="product-child-img" src={imag} alt="image" /> ) : (  
              <iframe
                height="315"
                src="https://www.youtube.com/embed/fh5T8SaAFYo?si=Gq_V4G4SHqwaCogs"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
              ></iframe>
            )}
            <h1>Programme</h1>
            <ul className="new-line">{programme}</ul>
          </div>
        </div>
      </div>
      <Order />
      <Form />
    </>
  );
};
