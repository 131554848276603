import React from "react";
import { Swiper, SwiperSlide } from "swiper/react"; // Import des composants React de Swiper
import "swiper/css"; // Styles de base de Swiper
import "swiper/css/navigation"; // Styles pour la navigation
import "swiper/css/pagination"; // Styles pour la pagination
import { Navigation, Pagination } from "swiper/modules"; // Import des modules nécessaires

export const Temoins = () => {
  return (
    <>
      <h1 style={{ textAlign: "center" }}>Témoignages</h1>
      <div className="tem-bloc">
        <div className="temoins">
          <Swiper
            modules={[Navigation, Pagination]} // Ajout des modules ici
            navigation // Activation de la navigation
            pagination={{ clickable: true }} // Activation de la pagination
            spaceBetween={50} // Espacement entre les slides
            slidesPerView={1} // Nombre de slides visibles
          >
            <SwiperSlide>
              <div className="temoins-child">
              <img width="96" height="96" src="https://img.icons8.com/color/96/gender-neutral-user.png" alt="gender-neutral-user"/>
                <p>Aïcha Djité </p>
              </div>
              <textarea>
                Je suis vraiment satisfaite et j’espère pouvoir bénéficier de
                vos formations. Merci!
              </textarea>
            </SwiperSlide>
            <SwiperSlide>
              <div className="temoins-child">
              <img width="96" height="96" src="https://img.icons8.com/color/96/gender-neutral-user.png" alt="gender-neutral-user"/>
                <p>Thierno Diaw </p>
              </div>
              <textarea>
                C'est bien génial c'est une bonne idée de faire ces platforme
                pour nous débutant d'apprendre comment faire des application et
                des site web.
              </textarea>
            </SwiperSlide>
            <SwiperSlide>
              <div className="temoins-child">
              <img width="96" height="96" src="https://img.icons8.com/color/96/gender-neutral-user.png" alt="gender-neutral-user"/>
                <p>Omar Diop</p>
              </div>
              <textarea>Honneur d'intégrer votre plate-forme</textarea>
            </SwiperSlide>
            <SwiperSlide>
              <div className="temoins-child">
              <img width="96" height="96" src="https://img.icons8.com/color/96/gender-neutral-user.png" alt="gender-neutral-user"/>
                <p>Papa Dieng</p>
              </div>
              <textarea>Félicitations pour cette belle initiative</textarea>
            </SwiperSlide>
            <SwiperSlide>
              <div className="temoins-child">
              <img width="96" height="96" src="https://img.icons8.com/color/96/gender-neutral-user.png" alt="gender-neutral-user"/>
                <p>Amadou Ndao</p>
              </div>
              <textarea>J'ai bien aimé vôtre initiative </textarea>
            </SwiperSlide>
            <SwiperSlide>
              <div className="temoins-child">
              <img width="96" height="96" src="https://img.icons8.com/color/96/gender-neutral-user.png" alt="gender-neutral-user"/>
                <p>Jules Sankaré</p>
              </div>
              <textarea>j'apprécie beaucoup cette formation </textarea>
            </SwiperSlide>
            <SwiperSlide>
              <div className="temoins-child">
              <img width="96" height="96" src="https://img.icons8.com/color/96/gender-neutral-user.png" alt="gender-neutral-user"/>
                <p>Cheikh Maremou Ndiaye</p>
              </div>
              <textarea>Je pense améliorer mon niveau </textarea>
            </SwiperSlide>
            <SwiperSlide>
              <div className="temoins-child">
              <img width="96" height="96" src="https://img.icons8.com/color/96/gender-neutral-user.png" alt="gender-neutral-user"/>
                <p>Oumy khairy</p>
              </div>
              <textarea>Je vous déclare les meilleurs </textarea>
            </SwiperSlide>
            <SwiperSlide>
              <div className="temoins-child">
              <img width="96" height="96" src="https://img.icons8.com/color/96/gender-neutral-user.png" alt="gender-neutral-user"/>
                <p>Amadou Fall</p>
              </div>
              <textarea>Merci</textarea>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};
