import React from "react";
import { useNavigate } from "react-router-dom";

export const ServiceListHome = () => {
  const navigate = useNavigate()
 // L'ensemble des services qui sont sur la page d'accueil
  function nav(link){
    navigate(`${link}`)
  }
  return (
    <>
      <div className="text">
        <svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 640 512"  style={{ width: "10%" }}>
          <path
            fill="#eef0f6"
            d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"
          />
        </svg>
        <h1  id="para">
          Nous sommes à l'avant-garde de l'innovation numérique au Sénégal.💻
        </h1>
        <p>
          Confiez-nous vos
          <span>
            {" "}
            projets web sur mesure, quelle que soit leur envergure. <br />
            Nous gérons tous types de sites, assurons leur sécurité, et vous
            offrons bien plus :
          </span>{" "}
          et réussi (e) , <br /> 🔧 Formations en développement web (Node.js,
          back-end, etc.) <br />
          🌍 Communauté de développeurs sénégalais et africains <br /> 💼
          Découverte et publication de projets <br />
          🔍 Recommandations et conseils pour vos sites web <br /> 📚 Exercices
          pratiques pour progresser en développement
        </p>
      </div>
      <div className="products">
        <div className="product">
          <h1>
            Formation Développement <br />
            Web et logiciel
          </h1>
          <img src="./images/Developer activity-bro.svg" alt="python"  style={{width:'58%'}}/>
          <p>
            Accédez à des formations pratiques en ligne,<br/>
            pécialement conçues pour vous aider à maîtriser <br/> des technologies.
            comme Node.js et le développement back-end <br/>
            quel que soit votre niveau..
          </p>
          <br/>
          <a>A partir de: 8.500 Francs</a>
          <a href="" onClick={(e)=>{
            e.preventDefault()
            nav('/formations')
          }}>Details</a>
        </div>
        <div className="product">
          <h1>
            Création Site web et logiciel
          </h1>
          <img src="./images/coding-bro.svg" alt="python" style={{width:'58%'}} />
          <p>
            Nous créons des sites web adaptés à vos besoins
            <br />
            quelle que soit leur taille ou leur complexité ainsi que des logiciels.
            <br />
            Faites-nous confiance pour gérer votre projet de A à Z,
            <br />
            en incluant la conception, le développement et la sécurité.
          </p>
          <br/>
          <a>A partir de: 150.000 Francs</a>
          <a href="" onClick={(e)=>{
            e.preventDefault()
            nav('/coming')
          }}>
            Details
          </a>
        </div>
        <div className="product">
          <h1>
            Publication de projets
            <br />
          </h1>
          <img src="./images/Website Creator-bro.svg" alt="python" style={{width:'58%'}} />
          <p>
            Parcourez nos projets réalisés pour découvrir <br/> notre expertise
            ou publiez vos propres projets <br/> sur notre plateforme
            données.pour bénéficier de feedbacks <br/> et d'améliorations.
          </p>
          <a>.......</a>
          <a href="" onClick={(e)=>{
            e.preventDefault()
            nav('/coming')
          }}>Parcourir...</a>
        </div>
        <div className="product">
          <h1>
            Communauté <br /> développeur
          </h1>
          <img src="./images/Low code development-pana.svg" alt="python" style={{width:'68%'}} />
          <p>
            Rejoignez une communauté active de développeurs <br/> du Sénégal 
            et d'Afrique.Partagez vos connaissances, <br/>
            collaborez sur des projets et grandissez ensemble <br/> dans l'univers du
            développement.
          </p>
          <br/>
          <a>Decouvrir...</a>
          <a href="" onClick={(e)=>{
            e.preventDefault()
            nav('/coming')
          }}>Rejoindre maintenant</a>
        </div>
      </div>
    </>
  );
};
