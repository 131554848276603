import React from "react";
import { IntegrateCourses } from "./integrateFormation";
import { SocialMedia } from "./social.media";
import { Order } from "./CourseOrder";

//  Bloc d' accueil de la page de formation
export const Welcom = () => {
  return (
    <>
      <div className="text">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style={{ width: "10%" }}>
          <path fill="#eef0f6"
            d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"
          />
        </svg>
        <h1 id="para">Découvrez des formations de qualités 💻</h1>
        <p>
          Devenir <span> développeur/développeuse Full stack </span> et réussi
          (e) , <br /> apprendre à manipuler de grandes quantités de données,
          gérer des bases de <br />
          données assurer la disponibilité des datas , <br /> assurer les
          connexions et déconnexions des utilisateurs, <br /> crypter et
          décrypter des informations sensibles, <br /> hachage de mot de passe
          interaction avec le front-end <br /> react , concevoir des
          applications web robustes et solides🖥💻⚙
        </p>
      </div>
      <div className="content-int">
          <h1 style={{margin:'10px'}}>Découvrez des formations avancées</h1>
          <IntegrateCourses/>   {/*Afficher les  formations disponibles*/}
          <Order />
          <SocialMedia />
          </div>
    </>
  );
};
